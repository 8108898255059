import {
  Breakpoints,
  compact,
  type HeaderEntry,
  HeaderEntryTextColor,
  IconGraduateHat,
  IconInbox,
  IconProfile,
  IconQuestion,
  IconSignOut,
  IconSwitch,
  IconWarningTriangle,
  type NavProps,
  NavTheme,
  PageHeader,
  type SubNavProps,
  useMediaQuery,
} from '@leland-dev/leland-ui-library';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { type FC, useMemo } from 'react';

import { useAuth } from '../context/AuthContext';
import { LexSlug, useLex } from '../context/LexContext';
import {
  APPLICANT_SIGNUP_URL,
  APPLICANT_SUBSCRIBER_DASHBOARD_URL,
  COACH_BASE_URL,
  isServer,
  LELAND_PLUS_REDIRECT_URL,
  PARTNERSHIPS_LP_URL,
  SUPPORT_URL,
} from '../utils/constants';
import { LelandImpersonation } from '../utils/impersonation';
import { generateSearchPath } from '../utils/routing';

import {
  type LulNavbarNavCategoryListingFragment,
  useLulNavbarUserQuery,
} from './__generated-gql-types__/LulNavbar.generated';

const InboxCustomIcon = dynamic(
  () => import('../components/nav/InboxCustomIcon'),
  {
    ssr: false,
  },
);

export interface LulNavbarProps {
  hideSubnav?: boolean;
  pageContentData: Possible<{
    navCategoryListings: LulNavbarNavCategoryListingFragment[];
  }>;
}

export const LulNavbar: FC<LulNavbarProps> = ({ pageContentData }) => {
  const router = useRouter();
  const { isNewSubscriptionExperience, isImpersonating } = useAuth();
  const { isLexEnabled } = useLex();
  const { data: userData, loading: userLoading } = useLulNavbarUserQuery();
  const isSm = useMediaQuery(Breakpoints.SM);
  const isMd = useMediaQuery(Breakpoints.MD);

  const IMPERSONATION_HEADER_ENTRY: HeaderEntry = useMemo(
    () => ({
      label: 'EXIT IMPERSONATION',
      icon: IconWarningTriangle,
      textColor: HeaderEntryTextColor.RED,
      onClick: () => {
        LelandImpersonation.removeImpersonatedUserId();

        void router.push(`${COACH_BASE_URL}/internal/ops/customers`);
      },
    }),
    [router],
  );

  const navCategories: NavProps['categories'] = isNewSubscriptionExperience
    ? undefined
    : pageContentData?.navCategoryListings.map(({ name, goal, categories }) => {
        return {
          url: goal
            ? generateSearchPath({
                goal,
                categorySlug: null,
                subCategorySlug: null,
              })
            : undefined,
          label: name,
          subItems: categories?.map(
            ({
              name,
              goal,
              slug,
              subCategoryList,
              shouldShowSubCategories,
            }) => {
              return {
                url: generateSearchPath({
                  goal,
                  categorySlug: slug,
                  subCategorySlug: null,
                }),
                label: name,
                subItems: shouldShowSubCategories
                  ? subCategoryList?.map(({ name, slug: subCategorySlug }) => {
                      return {
                        url: generateSearchPath({
                          goal,
                          categorySlug: slug,
                          subCategorySlug,
                        }),
                        label: name,
                      };
                    })
                  : undefined,
              };
            },
          ),
        };
      }) ?? [];

  const profileMenuItemSections: HeaderEntry[][] = useMemo(() => {
    const sections: Array<Nullable<Array<Nullable<HeaderEntry>>>> = [
      [isImpersonating ? IMPERSONATION_HEADER_ENTRY : null],
      [
        {
          url: userData?.user?.applicant ? '/settings' : COACH_BASE_URL,
          icon: IconProfile,
          label: 'Profile',
        },
        userData?.user?.coach
          ? {
              url: COACH_BASE_URL,
              icon: IconSwitch,
              label: 'Switch to Coaching',
            }
          : {
              url: `/become-a-coach`,
              icon: IconGraduateHat,
              label: 'Become a Coach',
            },
      ],
      [
        {
          url: SUPPORT_URL,
          icon: IconQuestion,
          label: 'Help & Support',
        },
        {
          url: '/logout',
          icon: IconSignOut,
          label: 'Log Out',
        },
      ],
    ];
    return sections.reduce<HeaderEntry[][]>(
      (reducedSections, currentSection) => {
        const filteredCurrentSection = currentSection?.filter(compact);
        if (filteredCurrentSection?.length) {
          return [...reducedSections, filteredCurrentSection];
        }
        return reducedSections;
      },
      [],
    );
  }, [
    IMPERSONATION_HEADER_ENTRY,
    isImpersonating,
    userData?.user?.applicant,
    userData?.user?.coach,
  ]);

  const leftLinks: NavProps['leftLinks'] = useMemo(() => {
    const links: NavProps['leftLinks'] = [];
    if (isSm) {
      if (isNewSubscriptionExperience) {
        links.push({
          label: 'Dashboard',
          url: APPLICANT_SUBSCRIBER_DASHBOARD_URL,
        });
      }
      if (isImpersonating) {
        links.push(IMPERSONATION_HEADER_ENTRY);
      }
    }
    return links.length ? links : undefined;
  }, [
    IMPERSONATION_HEADER_ENTRY,
    isImpersonating,
    isNewSubscriptionExperience,
    isSm,
  ]);

  const isActiveLelandPlus = userData?.user?.applicant?.lelandPlus?.active;

  const rightLinks: NavProps['rightLinks'] = useMemo(() => {
    const links: NavProps['rightLinks'] = [];
    if (isSm) {
      links.push({
        label: 'Free events',
        url: `/events`,
      });
    }
    if (userData?.user) {
      if (isSm && isActiveLelandPlus) {
        links.push({
          label: 'Leland+',
          url: LELAND_PLUS_REDIRECT_URL,
          isExternal: true,
        });
      }
      links.push({
        label: 'Inbox',
        customIconComponent: InboxCustomIcon as FC,
        icon: isServer ? IconInbox : undefined,
        hideLabel: !isSm,
        url: `/inbox`,
      });
    } else if (isSm) {
      links.push({
        label: 'Become a coach',
        url: `/become-a-coach`,
      });
      if (isMd) {
        links.push({
          label: 'Partnerships',
          url: PARTNERSHIPS_LP_URL,
        });
      }
    } else {
      links.push({
        label: 'Join',
        url: APPLICANT_SIGNUP_URL,
      });
    }
    return links;
  }, [isSm, isMd, userData?.user, isActiveLelandPlus]);

  const mobileSidebarLinks: HeaderEntry[] = useMemo(() => {
    return [
      isImpersonating ? IMPERSONATION_HEADER_ENTRY : null,
      {
        label: 'Inbox',
        url: '/inbox',
      },
      isNewSubscriptionExperience
        ? {
            label: 'Dashboard',
            url: APPLICANT_SUBSCRIBER_DASHBOARD_URL,
          }
        : null,
      {
        label: 'Free events',
        url: `/events`,
        isExternal: true,
      },
      userData?.user?.applicant?.lelandPlus?.active
        ? {
            label: 'Leland+',
            url: LELAND_PLUS_REDIRECT_URL,
            isExternal: true,
          }
        : null,
    ].filter(compact);
  }, [
    IMPERSONATION_HEADER_ENTRY,
    isImpersonating,
    isNewSubscriptionExperience,
    userData?.user?.applicant?.lelandPlus?.active,
  ]);

  const accountLinks: HeaderEntry[] = useMemo(() => {
    return userData
      ? [
          {
            label: 'Profile',
            url: userData?.user?.applicant ? '/settings' : COACH_BASE_URL,
          },
          {
            label: 'Order History',
            url: '/settings/order-history',
          },
          {
            label: 'Payment Details',
            url: '/settings/payment-details',
          },
          {
            label: 'Log out',
            url: '/logout',
            textColor: HeaderEntryTextColor.RED,
          },
        ]
      : [];
  }, [userData]);

  const navProps: NavProps = {
    theme: isImpersonating ? NavTheme.RED : undefined,
    urls: {
      home: '/',
      login: '/login',
      signup: APPLICANT_SIGNUP_URL,
      inbox: '/inbox',
    },
    rightLinks,
    leftLinks,
    timeBalanceSection:
      isNewSubscriptionExperience &&
      !isLexEnabled(LexSlug.FEATURE_APPLICANT_SELF_SERVE)
        ? {
            timeRemainingInMinutes:
              userData?.user?.applicant?.timeCreditAvailable ?? 0,
            href: APPLICANT_SUBSCRIBER_DASHBOARD_URL,
          }
        : undefined,
    mobileLinks: {
      sidebar: mobileSidebarLinks,
      footer: [
        {
          label: 'Home',
          url: '/',
        },
        {
          label: 'Become a coach',
          url: `/become-a-coach`,
        },
        {
          label: 'Partnerships',
          url: PARTNERSHIPS_LP_URL,
          isExternal: true,
        },
        {
          label: 'Support',
          url: SUPPORT_URL,
        },
      ],
      browseCoaching: navCategories,
      accountMenu: accountLinks,
    },
    profileMenuItemSections,
    user: userData?.user
      ? {
          ...userData.user,
          profileLink: userData.user.applicant ? '/settings' : COACH_BASE_URL,
        }
      : undefined,
    userLoading,
  };

  // TODO: Add subnav props once we have pages with subnavs
  const subNavProps: Optional<SubNavProps> = undefined;

  return <PageHeader navProps={navProps} subNavProps={subNavProps} sticky />;
};
